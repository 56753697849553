

















































































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import useBase64 from "@/use/base64";
import { v4 as uuid } from "uuid";

interface StepError {
  step: number;
  error: string;
}

export default defineComponent({
  components: {
    OParticipantInfo: () =>
      import("@/components/organisms/event/participant/o-participant-info.vue"),
    OParticipantFees: () =>
      import("@/components/organisms/event/participant/o-participant-fees.vue"),
    OParticipantInvoice: () =>
      import(
        "@/components/organisms/event/participant/o-participant-invoice.vue"
      ),
    OParticipantAdditionalServices: () =>
      import(
        "@/components/organisms/event/participant/o-participant-additional-services.vue"
      ),
    OParticipantAccommodation: () =>
      import(
        "@/components/organisms/event/participant/o-participant-accommodation.vue"
      ),
    OParticipantSummary: () =>
      import(
        "@/components/organisms/event/participant/o-participant-summary.vue"
      ),
  },

  setup(_, { root }) {
    const model = reactive<{
      stepper: number;
      data: any;
      fees: any;
      invoice: any;
      additionalServices: any;
      accommodation: any;
    }>({
      stepper: 1,
      data: {
        type: "participant",
        languageVersion: "polish",
        notePriority: "niski",
        status: "participant",
        sendEmail: false,
        sendProForma: false,
      },
      fees: {
        cost: "",
        discountCode: null,
        companion: null,
        registrationCode: null,
      },
      invoice: {
        invoicePurchaser: "none",
        invoiceConsent: false,
        electronicInvoiceConsent: false,
      },
      additionalServices: [],
      accommodation: {},
    });

    const { toBase64 } = useBase64();

    const dataSummary = computed(() => ({
      data: model.data,
      invoice: model.invoice,
      additionalServices: model.additionalServices,
    }));

    const state = reactive({
      loading: false,
      success: false,
      error: false as boolean | number,
      errors: computed((): StepError[] => {
        const errors: StepError[] = [];

        if (!model.data.firstName)
          errors.push({
            step: 1,
            error: root.$tc("layout.misc.fillParticipantName"),
          });
        if (!model.data.lastName)
          errors.push({
            step: 1,
            error: root.$tc("layout.misc.fillParticipantLastName"),
          });
        if (!model.data.email)
          errors.push({ step: 1, error: root.$tc("layout.misc.enterEmail") });
        if (!model.fees.cost)
          errors.push({
            step: 2,
            error: root.$tc("layout.misc.chooseRegistrationFee"),
          });

        return errors;
      }),
      participantPhoto: "",
      currency: "",
      servicesReserveList: [] as any,
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isStepEditable = (step: number) => {
      // return model.stepper > step
      return true;
    };

    const isStepComplete = (step: number) => {
      return (
        model.stepper > step &&
        !state.errors.find((error) => error.step == step)
      );
    };

    const isStepValid = (step: number) => {
      return !state.errors.find((error) => error.step == step);
    };

    const isFormValid = computed(() => !state.errors.length);

    const goToErrors = () => {
      let lowest = Number.POSITIVE_INFINITY;
      let highest = Number.NEGATIVE_INFINITY;

      let tmp: number;

      for (let i = state.errors.length - 1; i >= 0; i--) {
        tmp = state.errors[i].step;
        if (tmp < lowest) lowest = tmp;
        if (tmp > highest) highest = tmp;
      }

      model.stepper = lowest;
    };

    watch(
      () => model.data.photo,
      async (newPhoto) => {
        state.participantPhoto = (await toBase64(newPhoto)) as string;
      }
    );

    // Fetch event

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          state.currency = event.currency;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchEvent);

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 406:
          return `${root.$tc("panel.event.participant.add.error406")}`;
        case 407:
          return `${root.$tc("panel.event.participant.add.error407")}`;
        case 409:
          return `${root.$tc("layout.errors.participantAlreadyExists")}`;
        case 410:
          return `${root.$tc("panel.event.participant.add.error410")}`;
        case 414:
          return `${root.$tc("panel.event.participant.add.error414")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      let imageId = "";
      const formData = new FormData();

      formData.append("image", model.data.photo);

      if (model.data.photo) {
        await axiosInstance
          .post("image/participant", formData)
          .then(({ data: { id } }) => {
            imageId = id;
          })
          .catch((e) => console.log(e));
      }

      const tempReserve = state.servicesReserveList.reduce(
        (acc: any, cur: any) => {
          if (!acc.find((item: any) => item.id === cur.id)) {
            acc.push(cur);
          }
          return acc;
        },
        []
      );

      const data = {
        type: model.data.type,
        firstName: model.data.firstName,
        lastName: model.data.lastName,
        gender: model.data.gender || undefined,
        languageVersion: model.data.languageVersion || undefined,
        image: imageId || undefined,
        state: model.data.status,
        degree: model.data.degree || undefined,
        specializations: model.data.specialization || undefined,
        licenseNumber: model.data.licenseNumber || undefined,
        studentNumber: model.data.studentNumber || undefined,
        description: model.data.description || undefined,
        note: model.data.note || undefined,
        notePriority: model.data.notePriority || undefined,
        sponsor: model.data.sponsor || undefined,
        marketingConsent: model.data.marketingConsent,
        emailOffersConsent: model.data.emailOffersConsent,
        phoneOffersConsent: model.data.phoneOffersConsent,
        termsConsent: model.data.termsConsent,
        sendEmail: model.invoice.sendProForma
          ? true
          : model.data.sendEmail || false,
        phonePrefix: model.data.phonePrefix || undefined,
        phoneNumber: model.data.phone
          ? model.data.phone.replace(/ /g, "")
          : undefined,
        email: model.data.email,
        country: model.data.country || undefined,
        addressCity: model.data.addressCity || undefined,
        addressPostalCode: model.data.addressPostalCode || undefined,
        addressStreet: model.data.addressStreet || undefined,
        institutionName: model.data.institutionName || undefined,
        institutionPosition: model.data.institutionPosition || undefined,
        institutionCity: model.data.institutionCity || undefined,
        institutionPostalCode: model.data.institutionPostalCode || undefined,
        institutionStreet: model.data.institutionStreet || undefined,
        invoicePurchaser: model.invoice.invoicePurchaser || "none",
        invoiceName: model.invoice.invoiceName || undefined,
        invoiceStreet: model.invoice.invoiceStreet || undefined,
        invoicePostalCode: model.invoice.invoicePostalCode || undefined,
        invoiceCity: model.invoice.invoiceCity || undefined,
        invoiceEmail: model.invoice.invoiceEmail || undefined,
        invoiceVatNumber: model.invoice.invoiceVatNumber || undefined,
        invoiceNumber: model.invoice.invoiceNumber || undefined,
        dateOfInvoice: model.invoice.dateOfInvoice || undefined,
        invoiceConsent:
          model.invoice.invoicePurchaser === "individual"
            ? model.invoice.invoiceConsent
            : false,
        electronicInvoiceConsent:
          model.invoice.electronicInvoiceConsent || false,
        sendProForma: model.invoice.sendProForma || false,
        registrationFeeTermData: {
          id: model.fees.cost,
          discountCodeValue: model.fees.discountCode || null,
          registrationCode: model.fees.registrationCode,
        },
        companion: model.fees.companion,
        additionalServicesData: model.additionalServices.concat(tempReserve),
        participantGroupIds: model.data.group
          ? model.data.group.map((el: any) => el.id)
          : [],
        accommodationData: model.accommodation?.roomId
          ? model.accommodation
          : null,
      };

      state.loading = true;

      axiosInstance
        .post(`/event/${root.$route.params.id}/participant`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          const reset = () => {
            state.success = true;
            model.stepper = 1;
            model.data = {
              type: "participant",
              languageVersion: "polish",
              notePriority: "niski",
              status: "participant",
              sendEmail: false,
            };
            model.invoice = {
              invoicePurchaser: "none",
              invoiceConsent: false,
            };
            model.fees = {
              cost: "",
            };
          };
          reset();
          state.loading = false;
        })
        .catch((error) => {
          state.error = error.response.status;
          state.loading = false;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        });
    };

    return {
      state,
      model,
      isStepEditable,
      isStepComplete,
      isStepValid,
      isFormValid,
      goToErrors,
      dataSummary,
      onSubmit,
      getErrorMessage,
    };
  },
});
